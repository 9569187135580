import React, {useEffect, useState} from "react";
import {ScrollView, StyleSheet} from "react-native";
import {Button, Text} from "@rneui/themed";
import {useNavigation} from "@react-navigation/native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import AsyncStorage from '@react-native-async-storage/async-storage';

const getKeyFromLocalStorage = async (key: string) => {
    try {
        return await AsyncStorage.getItem(key)
    } catch (error) {
    }
};
export default function HowToPlay() {
    const navigation = useNavigation();
    const [isLocalStorageLoaded, setIsLocalStorageLoaded] = useState<boolean>(false);


    useEffect(() => {
        getKeyFromLocalStorage("isFirstTime")
            .then(localIsFirstTime => {
                setIsLocalStorageLoaded(true)
                if (localIsFirstTime === "false") {
                    //@ts-ignore
                    navigation.navigate("Home")

                }
            })
            .catch(error => {
            });
    }, [])

    const handleButtonClick = () => {
        // @ts-ignore
        navigation.navigate("Home");
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>
            {isLocalStorageLoaded && <>
                <Text style={styles.header}>ברוך הבא לבאלגן!</Text>
                <Text style={[styles.text]}>
                    באלגן הוא משחק חברה מהנה ומאתגר שיראה מה אתם שווים.
                </Text>
                <Text style={[styles.text, {marginBottom: 30}]}>
                    המשחק כולל מגוון רחב של משימות ויבדוק את
                    קצה גבול היכולת שלכם.
                </Text>
                <Text style={styles.header}>מגניב, איך משחקים?</Text>
                <FontAwesomeIcon name="rocket" color={"white"} size={24} style={styles.icon}/>
                <Text style={styles.text}>
                    לאורך המשחק, השחקנים צוברים פסילות
                </Text>
                <FontAwesomeIcon name="rocket" color={"white"} size={24} style={styles.icon}/>
                <Text style={styles.text}>
                    שחקן עם 5 פסילות נדרש לבצע משימה, כפי שמחליטה הקבוצה.
                </Text>

                <Text style={[styles.text, {color: "#00b9bc"}]}>
                    המשפטים מנוסחים בלשון זכר אך פונים לכל המינים

                </Text>
                <Text style={styles.space}></Text>
                <Button
                    style={styles.button}
                    titleStyle={styles.buttonText}
                    type="outline"
                    onPress={() => handleButtonClick()}>
                    הבנתי יאח
                </Button>
            </>}


        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        alignItems: "center",
        flexGrow: 1,
        justifyContent: "center",
    },
    header: {
        marginBottom: 10,
        fontSize: 34,
        textDecorationLine: "underline",
        color: "#f0f0f0",
    },
    text: {
        direction: "rtl",
        writingDirection: "rtl",
        marginVertical: 7,
        textAlign: "center",
        color: "#f0f0f0",
        fontSize: 20,
    },
    icon: {
        color: "#00b9bc"
    },
    space: {
        height: 20, // Add more space between the button and text
    },
    button: {
        width: 200,
        borderColor: "#00b9bc",
        borderRadius: 8,
        borderWidth: 1,

    },
    buttonText: {
        color: "#f0f0f0",
    },
});
