import User from "../models/User";
import {View} from "react-native";
import React from "react";
import {useDispatch} from "react-redux";
import {Chip} from "@rneui/themed";
import {deleteUser} from "../state/slices/UserSlice";


interface userProps {
    user: User
}

export default function SelectedUser(props: userProps) {
    const dispatch = useDispatch()

    return (
        <View>
            <Chip
                type={"outline"}

                title={props.user.name}
                onPress={() => dispatch(deleteUser(props.user.id))}
                color="white"

                icon={{
                    type: "font-awesome",
                    name: 'remove',
                    size: 20,
                    color: 'white',
                }}
                iconRight
                titleStyle={{
                    color: "white"
                }
                }
                containerStyle={{
                    marginVertical: 7,
                    padding: 2
                }}
                buttonStyle={{
                    borderColor: "#00b9bc",
                    borderWidth: 0.5,
                }}
                style={{
                    margin: 4
                }}
            />
        </View>
    )

}