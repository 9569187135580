import React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {PricingCard} from '@rneui/themed';
import {useDispatch} from "react-redux";
import {setMode} from "../state/slices/AppSlice";
import {useNavigation} from "@react-navigation/native";
import {Mode} from "../models/Event"

const ChooseMode = () => {
    const dispatch = useDispatch()
    const navigation = useNavigation();

    const handleModeClick = (newMode: Mode) => {
        dispatch(setMode(newMode))
        // @ts-ignore
        navigation.navigate("Game")

    }
    return (
        <>
            <ScrollView style={styles.container}>
                <PricingCard
                    color={"#00B9BC"}
                    title="רגוע"
                    onButtonPress={() => handleModeClick(Mode.CHILL)}
                    pricingStyle={styles.pricingCardPricingStyle}
                    price={"חינם"}
                    info={["הדרך הכי טובה להתחיל", "משימה לדוגמה - לשלם את הטיפ על השולחן"]}
                    containerStyle={styles.pricingCardContainerStyle}
                    infoStyle={styles.pricingCardTextStyle}
                    button={{
                        title: 'יאללה באלגן', icon: {
                            type: "font-awesome",
                            name: 'flash',
                            size: 20,
                            style: {marginRight: 4},
                            color: 'white',
                        }, buttonStyle: styles.pricingCardButtonStyle
                    }}
                />
                <PricingCard
                    color={"#00B9BC"}
                    title="אקטיבי"
                    onButtonPress={() => handleModeClick(Mode.ACTIVE)}
                    pricingStyle={styles.pricingCardPricingStyle}
                    price={"חינם"}
                    containerStyle={styles.pricingCardContainerStyle}
                    infoStyle={styles.pricingCardTextStyle}
                    info={["מתאים לגיבוש משפחתי, פעולות בתנועות נוער", "משימה לדוגמה - 10 שכיבות שמיכה"]}
                    button={{
                        title: 'יאללה באלגן', icon: {
                            type: "font-awesome",
                            name: 'child',
                            style: {marginRight: 4},
                            size: 20,
                            color: 'white',
                        }, buttonStyle: styles.pricingCardButtonStyle

                    }}
                />
                <PricingCard
                    color={"#E63946"}
                    pricingStyle={styles.pricingCardPricingStyle}
                    price={"חינם"}
                    title="לוהט"
                    onButtonPress={() => handleModeClick(Mode.HOT)}
                    info={["18+", "מתאים לקבוצות", "משימה לדוגמה - להוריד צייסר או בגד"]}
                    infoStyle={styles.pricingCardTextStyle}

                    containerStyle={styles.pricingCardContainerStyle}
                    button={{
                        title: 'יאללה באלגן', icon: {
                            type: "font-awesome",
                            name: 'glass',
                            style: {marginRight: 4},
                            size: 20,
                            color: 'white',
                        }, buttonStyle: styles.pricingCardButtonStyle
                    }}
                />
            </ScrollView>
        </>
    );
};
const styles = StyleSheet.create({
    container: {
        padding: 18,
        marginTop: 15,
    },
    pricingCardContainerStyle: {
        backgroundColor: "#023047",
        borderColor: "#00B9BC",
    },
    pricingCardTextStyle: {
        color: "white"
    },
    pricingCardPricingStyle: {
        color: "white"
    },
    pricingCardButtonStyle: {
        borderRadius: 8,
    }

})
export default ChooseMode;