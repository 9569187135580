import {Pressable, StyleSheet, View} from "react-native";

import React, {useEffect, useState} from "react";
import {Event, eventType, Mode} from "../models/Event";
import pocketBaseService from "../server/services/impl/PocketBaseService";
import {useNavigation} from "@react-navigation/native";
import {Text} from "@rneui/themed";
import {useSelector} from "react-redux";
import {RootState} from "../state/Store";
import User from "../models/User";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";


const numberOfEvents = 25;

interface eventSettings {
    header: string,
    backgroundColor: string
}

const regularGameSettings: eventSettings = {header: "", backgroundColor: "#023047"};

const adsFreq: number = 2;

export default function Game() {
    const [currentEventIndex, setCurrentEventIndex] = useState<number>(0)
    const [events, setEvents] = useState<Event[]>([])
    let currentEvent: Event = events[currentEventIndex]
    const [eventSettings, setEventSettings] = useState<eventSettings>(regularGameSettings)
    const navigation = useNavigation();
    const users: User[] = useSelector((state: RootState) => state.users.users)
    const isFirstTime: boolean = useSelector((state: RootState) => state.app.isFirstTime)
    const mode: Mode = useSelector((state: RootState) => state.app.mode)

    useEffect(() => {
        if (mode !== undefined) {
            pocketBaseService.logGameStart(mode, users,isFirstTime).then(res => res).catch(e => {
            })
        }
    }, [mode]);

    useEffect(() => {
        if (currentEvent !== undefined) {
            switch (currentEvent.event_type) {
                case eventType.GAME: {
                    setEventSettings({header: "משחק", backgroundColor: "#00b407"})
                    break

                }
                case eventType.VIRUS: {
                    setEventSettings({header: "וירוס!", backgroundColor: "#e7bb00"})
                    break
                }
                default:
                    setEventSettings(regularGameSettings)
                    break
            }

        }


    }, [currentEvent])
    useEffect(() => {
        if (mode !== undefined) {
            pocketBaseService.getGameEvents(numberOfEvents, users, mode).then(events => {
                setEvents(events)
            })
        } else {
            // @ts-ignore
            navigation.navigate("Home")
        }
    }, [])

    const getNextEvent = (): void => {
        if (currentEventIndex == events.length - 1) {
            // @ts-ignore
            navigation.navigate("Home")
        }
        setCurrentEventIndex(currentEventIndex + 1)

    }

    return (
        <Pressable
            style={[styles.container, {backgroundColor: eventSettings.backgroundColor}]}
            onPress={() => getNextEvent()}>

            {events[currentEventIndex] && <View style={styles.eventContainer}>
                <Text style={[styles.eventHeader]}
                      h1={true}>{eventSettings.header}</Text>
                < Text style={styles.eventText} h2={true}>{currentEvent.content}</Text>
            </View>}
            <FontAwesomeIcon style={styles.homeButton} name="home" color={"white"} size={50} onPress={
                // @ts-ignore
                () => navigation.navigate("Home")}/>

        </Pressable>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    eventContainer: {
        width: "90%",
    },
    eventHeader: {
        textDecorationLine: "underline",
        textAlign: "center",
        fontWeight: "bold",
        color: "white"
    },
    eventText: {
        textAlign: "auto",
        alignSelf: "center",
        direction: "rtl",
        writingDirection: "rtl",
        color: "white",
    },
    homeButton: {
        position: "absolute",
        bottom: 0,
        marginBottom: 30
    },
});