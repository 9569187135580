export function replaceWordsWithDict(dict: Record<string, string>, inputString: string): string {
    const pattern = new RegExp(Object.keys(dict).join('|'), 'g');

    // Use the replace method with the regular expression to replace keys with values
    return inputString.replace(pattern, (match) => {
        // Use the matched key to get the corresponding value from the dictionary
        return dict[match] || match; // If not found, keep the original match
    });
}

export function shuffleArray(originalArray: any[]) {
    // @ts-ignore
    let array = [].concat(originalArray);
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}