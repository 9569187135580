export enum eventType {
    VIRUS = "virus",
    REGULAR = "regular",
    GAME = "game"
}

export enum Mode {
    HOT = "hot",
    CHILL = "chill",
    ACTIVE = "active"

}

export interface Event {
    content: string,
    number_of_players: number,
    event_type: eventType,
    mode: Mode
}
