import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice, nanoid} from '@reduxjs/toolkit'
import User from "../../models/User";

export interface UserState {
    users: User[],
}

const initialState: UserState = {
    users: [],
}

export const UserSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addUser: (state, action: PayloadAction<string>) => {
            let username: string = action.payload
            if (username != "") {
                state.users = [...state.users, {name: username, id: nanoid()}]
            }
        },
        deleteUser: (state, action: PayloadAction<string>) => {
            let id: string = action.payload
            state.users = state.users.filter((user) => user.id != id)

        },
    },
})


export const {addUser, deleteUser} = UserSlice.actions

export default UserSlice.reducer