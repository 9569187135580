import {DeviceType} from "expo-device";
import {Mode} from "./Event";


export const getDeviceStringType = (deviceType: DeviceType | null): string | null => {
    try {
        if (deviceType === null) {
            return null
        }
        const deviceTypeMap = {
            0: "unknown",
            1: "phone",
            2: "tablet",
            3: "desktop",
            4: "tv"
        }
        return deviceTypeMap[deviceType]
    } catch (e) {
        return "unknown"
    }

}


export interface GameDetails {
    deviceBrand: string | null
    deviceType: string | null
    deviceModelName: string | null
    os: string | null,
    mode: Mode,
    userNames: string
    isFirstTime: boolean
}