import Home from "./pages/Home";
import {Provider} from 'react-redux';
import {store} from "./state/Store";
import React, {useEffect} from "react";
import {DefaultTheme, NavigationContainer} from "@react-navigation/native";

import {createNativeStackNavigator} from "@react-navigation/native-stack";
import Game from "./pages/Game";
import ChooseMode from "./pages/ChooseMode";
import HowToPlay from "./pages/HowToPlay";
import {inject} from '@vercel/analytics';
import {Platform} from "react-native";


export type RootStackParamList = {
    Home: undefined;
    HowToPlay: undefined;
    Game: undefined
    ChooseMode: undefined
};
const Stack = createNativeStackNavigator<RootStackParamList>();

const AppTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: '#023047'
    },
};


export default function App() {
    useEffect(() => {
        if (Platform.OS == "web") {
            inject()

        }
    }, [])
    return (
        <>
            <Provider store={store}>
                {
                    <NavigationContainer theme={AppTheme}>

                        <Stack.Navigator initialRouteName='HowToPlay' screenOptions={{
                            headerShown: false,
                            gestureEnabled: true,

                        }}

                        >
                            <Stack.Screen
                                name="HowToPlay"
                                component={HowToPlay}
                                options={{
                                    title: "איך משחקים",
                                    orientation: 'portrait_up',
                                }}

                            />
                            <Stack.Screen
                                name="Home"
                                component={Home}
                                options={{
                                    title: "בלאגן",
                                    orientation: 'portrait_up',
                                }}

                            />

                            <Stack.Screen
                                name="ChooseMode"
                                component={ChooseMode}
                                options={{
                                    title: "בלאגן",
                                    orientation: 'portrait_up',
                                }}

                            />
                            <Stack.Screen
                                name="Game"
                                component={Game}
                                options={{
                                    title: "יאללה באלגן",
                                    orientation: 'landscape_right'
                                }}
                            />
                        </Stack.Navigator>
                    </NavigationContainer>}
            </Provider>
        </>

    );
}