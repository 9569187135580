import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {Mode} from '../../models/Event';

export interface AppSlice {
    mode: Mode
    isFirstTime: boolean
}

const initialState: AppSlice = {
    mode: Mode.CHILL,
    isFirstTime: true
}

export const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<Mode>) => {
            state.mode = action.payload
        },
        setIsFirstTime: (state, action: PayloadAction<boolean>) => {
            state.isFirstTime = action.payload
        },

    },
})


export const {setMode, setIsFirstTime} = AppSlice.actions

export default AppSlice.reducer