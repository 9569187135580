import {useDispatch, useSelector} from 'react-redux'
import {RootState} from "../state/Store";
import React, {useEffect, useState} from "react";
import {FlatList, Image, KeyboardAvoidingView, Modal, Pressable, StyleSheet, TextInput, View} from 'react-native';
import {addUser} from "../state/slices/UserSlice";
import SelectedUser from "../components/SelectedUser";
import {Button, Text} from '@rneui/themed';
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import {useNavigation} from "@react-navigation/native";
import User from "../models/User";
import AsyncStorage from '@react-native-async-storage/async-storage';


export default function Home() {

    const users: User[] = useSelector((state: RootState) => state.users.users)
    const [userName, setUserName] = useState<string>("")
    const dispatch = useDispatch()
    const navigation = useNavigation();
    const [wrongInputModal, setWrongInputModal] = useState<boolean>(false);


    const handleUserAdd = (username: string) => {
        dispatch(addUser(username))
        setUserName("")
    }

    useEffect(() => {
        AsyncStorage.setItem('isFirstTime', "false").then().catch((e) => e)
    }, [])

    const handleSubmitName = () => {
        if (users.length >= 1) {
            // @ts-ignore
            navigation.navigate("ChooseMode")

        } else {
            setWrongInputModal(true)
        }
    }

    return (
        <KeyboardAvoidingView style={styles.container} behavior="height" keyboardVerticalOffset={0}
        >
            <Modal
                animationType="fade"
                transparent={true}
                visible={wrongInputModal}
                onRequestClose={() => {
                    setWrongInputModal(!wrongInputModal);
                }}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalText}>אהלן, שכחת להוסיף שחקנים</Text>
                        <Pressable
                            style={styles.modalButton}
                            onPress={() => setWrongInputModal(!wrongInputModal)}>
                            <Text style={styles.modalTestStyle}>סגור</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>

            <Image source={require("../assets/icons/logo-no-background.png")}
                   style={{
                       alignSelf: "center", width: 310, height: 225, maxWidth: "100%", marginTop: "10%"
                   }}/>
            <FlatList style={styles.userContainer} inverted={true} data={users} numColumns={3}
                      keyExtractor={(item, index) => `${item.name}_${index}`}
                      renderItem={(user => {
                          return <SelectedUser user={user.item}/>
                      })}/>

            <View style={styles.addUserContainer}>
                <View style={styles.buttonsContainer}>
                    <Button buttonStyle={styles.button} type="outline" size={"md"}
                            onPress={() => handleSubmitName()}>
                        <FontAwesomeIcon name="check" color={"white"} size={24}/>
                    </Button>
                    <Button buttonStyle={styles.button} radius={"sm"} type="outline" size={"md"}
                            onPress={() => handleUserAdd(userName)}>
                        <FontAwesomeIcon name="plus" color={"white"} size={24}/>
                    </Button>
                </View>
                <TextInput placeholder={"שם שחקן"} style={styles.input} onSubmitEditing={() => handleUserAdd(userName)}
                           blurOnSubmit={false}
                           onChangeText={(username: string) => setUserName(username)} value={userName}/>
            </View>
        </KeyboardAvoidingView>
    )

}
// @ts-ignore
const styles = StyleSheet.create({
        mainHeader: {
            color: "white",
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            fontWeight: "bold",

        },
        container: {
            width: "100%",
            height: "100%",
            padding: 15,
        },
        buttonsContainer: {
            gap: 7,
            flexDirection: "row",
            width: "auto",
        },
        userContainer: {
            flexWrap: "wrap",
            flex: 1,
            direction: "rtl",
            writingDirection: "rtl",
            flexDirection: "row",
        },
        logo: {
            width: "100%",
            alignSelf: "center",
            top: 0,

        },
        addUserContainer: {
            flexDirection: "row",
            marginBottom: "2%",
            width: "100%",
            gap: 9,
        },

        input: {
            padding: 6,
            display: "flex",
            backgroundColor: '#f0f0f0',
            borderColor: "#00b9bc",
            borderWidth: 0.8,
            borderRadius: 10,
            textAlign: "right",
            flex: 1
        },
        button: {
            borderColor: "#00b9bc", // Change this to your desired background color
            borderRadius: 8, // Adjust the border radius as needed
            borderWidth: 0.7,
            width: 45
        },
        modalTestStyle: {
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
        },

        modalText: {
            marginBottom: 15,
            textAlign: 'center',
        },
        modalView: {
            margin: 20,
            backgroundColor: 'white',
            borderRadius: 20,
            padding: 35,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 22,
        },
        modalButton: {
            borderRadius: 20,
            padding: 10,
            elevation: 2,
            backgroundColor: "#243b55",
            borderColor: "#64ffda"


        },


    })
;
