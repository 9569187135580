import PocketBase from 'pocketbase'
import User from "../../../models/User";
import {Event, Mode} from "../../../models/Event";
import {replaceWordsWithDict, shuffleArray} from "../../utils";
import IEventService from "../IEventService";
import * as Device from 'expo-device';
import {GameDetails, getDeviceStringType} from "../../../models/GameDetails";

class PocketBaseService implements IEventService {
    pb: PocketBase

    constructor(baseUrl: string) {
        this.pb = new PocketBase(baseUrl,);
    }


    private getPlayerTag(users: User[]) {
        let playerTag: any = {}
        users = shuffleArray(users)
        users.forEach((user: User, index: number): void => {
            let player_number = index + 1;
            playerTag["שחקן" + player_number] = user.name
        })
        return playerTag
    }

    public logGameStart = async (mode: Mode, users: User[], isFirstTime: boolean) => {
        try {
            const userNames: string = users.map((user: User) => user.name).toString()
            const gameDetails: GameDetails = {
                os: Device.osName,
                deviceBrand: Device.brand,
                deviceType: getDeviceStringType(Device.deviceType),
                deviceModelName: Device.modelName,
                mode: mode,
                userNames: userNames,
                isFirstTime: isFirstTime
            }
            console.log(gameDetails)
            await this.pb.collection("gameLogs").create(gameDetails)
        } catch (e) {
            // logs are nice to have
        }
    }
    public getGameEvents = async (numberOfEvents: number, users: User[], mode: Mode): Promise<Event[]> => {
        let events: Event[] = await this.pb.collection("events").getList<Event>(1, numberOfEvents, {
            sort: "@random",
            filter: `mode ~ "${mode}" && number_of_players <= ${users.length}`
        }).then(events => events.items)

        events.forEach((event: Event) => {
            let playerTag = this.getPlayerTag(users);
            event.content = replaceWordsWithDict(playerTag, event.content)
            return event
        })
        return events
    }
}


const host: string = 'https://balagan-pb.luftig.online/'

const pocketBaseService: PocketBaseService = new PocketBaseService(host)

export default pocketBaseService

